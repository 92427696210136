import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Box, Text } from "@chakra-ui/core";
import BackgroundImage from "gatsby-background-image";
import PostEntryMeta from "gatsby-plugin-based-on-staticfuse/src/components/PostEntryMeta";
import Logo from "./Logo";

const NewsGrid = (props) => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        allNews {
          nodes {
            id
            title
            customFields {
              sourceurl
            }
            featuredImage {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  // remove link because it doesn't have the right url
  const filterExcerpt = (excerpt) =>
    excerpt.replace(/<a\b[^>]*>(.*?)<\/a>/i, "");

  const width = props.width || "250px";

  const posts = data.wpgraphql.allNews.nodes;

  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(auto-fill, minmax(${width}, 1fr))`}
      gridGap="20px"
      className="post-grid"
      p={props.noPadding ? "0" : 8}
    >
      {posts && posts.length
        ? posts.map((post, index) => (
            <Box
              key={post.id}
              gridColumn={props.featured && index === 0 ? "1 / -1" : ""}
            >
              {!props.noImage ? (
                <a
                  className="post-thumbnail"
                  href={post.customFields.sourceurl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {post.featuredImage ? (
                    <BackgroundImage
                      fluid={post.featuredImage.imageFile.childImageSharp.fluid}
                      style={{
                        height: "200px",
                        width: "100%",
                        maxWidth: "400px",
                        marginBottom: "1.5rem",
                        backgroundColor: "#eee",
                        float: props.featured && index === 0 ? "left" : "none",
                      }}
                    />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      bg="gray.50"
                      rounded="sm"
                      w="100%"
                      h="200px"
                      mb={4}
                    >
                      <Box m="auto" opacity=".5">
                        <Logo noLink />
                      </Box>
                    </Box>
                  )}
                </a>
              ) : null}
              <header className="entry-header">
                <a
                  href={post.customFields.sourceurl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p dangerouslySetInnerHTML={{ __html: post.title }}></p>
                </a>
              </header>

              {props.excerpt || (props.featured && index === 0) ? (
                <Text
                  className="entry-content"
                  dangerouslySetInnerHTML={{
                    __html: filterExcerpt(post.excerpt),
                  }}
                />
              ) : null}

              {props.meta || (props.featured && index === 0) ? (
                <PostEntryMeta post={post} location="grid" />
              ) : null}
            </Box>
          ))
        : null}
    </Box>
  );
};

export default NewsGrid;
